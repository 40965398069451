@font-face {
  font-family: 'comic-relief';
  src: url("../fonts/ComicRelief.ttf");
  /* IE9 Compat Modes */
  src: url("../fonts/ComicRelief.ttf") format("truetype"), url("../fonts/ComicRelief.ttf") format("truetype"), url("../fonts/ComicRelief.ttf") format("truetype"), url("../fonts/ComicRelief.ttf") format("truetype");
  /* Safari, Android, iOS */
}
/*
 * HOME
 */
/* line 4, ../sass/partials/_animations.scss */
.right-hand {
  animation-timing-function: linear;
  animation: rotate-arm 5s infinite;
  -webkit-transform-origin: 42px 160px;
  -ms-transform-origin: 42px 160px;
  -moz-transform-origin: 42px 160px;
  -o-transform-origin: 42px 160px;
  transform-origin: 42px 160px;
}

@keyframes rotate-arm {
  0% {
    transform: rotate(0deg);
  }
  7% {
    transform: rotate(-15deg);
  }
  15% {
    transform: rotate(0deg);
  }
  22% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(0deg);
  }
  65% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* line 24, ../sass/partials/_animations.scss */
.head {
  animation-timing-function: linear;
  animation: rotate-head 3s infinite;
  transform-origin: 100px 120px;
}
@media only screen and (max-width: 480px) {
  /* line 24, ../sass/partials/_animations.scss */
  .head {
    animation: none;
  }
}

@keyframes rotate-head {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}
/* line 39, ../sass/partials/_animations.scss */
.eye {
  animation-timing-function: linear;
  animation: blink-eye 3s infinite;
}

@keyframes blink-eye {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}
/* line 49, ../sass/partials/_animations.scss */
.eye-opposite {
  animation-timing-function: linear;
  animation: blink-eye-opposite 4s infinite;
}

@keyframes blink-eye-opposite {
  0% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
/* line 60, ../sass/partials/_animations.scss */
.bubble {
  animation-timing-function: linear;
  animation: move-bubble 4s infinite;
}
@media only screen and (max-width: 480px) {
  /* line 60, ../sass/partials/_animations.scss */
  .bubble {
    animation: none;
  }
}

@keyframes move-bubble {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(2px, 2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
/* line 74, ../sass/partials/_animations.scss */
.home-speech-bubble-text-1 {
  animation-timing-function: ease-in-out;
  animation: home-speech-bubble-text-1 6s infinite;
}

@keyframes home-speech-bubble-text-1 {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/* line 87, ../sass/partials/_animations.scss */
.home-speech-bubble-text-2 {
  animation-timing-function: ease-in-out;
  animation: home-speech-bubble-text-2 6s infinite;
}

@keyframes home-speech-bubble-text-2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 *  ABOUT
 */
/* line 104, ../sass/partials/_animations.scss */
.side-eye {
  animation-timing-function: linear;
  animation: show-side-eye 10s infinite;
}

@keyframes blink-straight-eye {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  27% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* line 123, ../sass/partials/_animations.scss */
.straight-eye {
  animation-timing-function: linear;
  animation: blink-straight-eye 10s infinite;
}

@keyframes show-side-eye {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* line 142, ../sass/partials/_animations.scss */
.mouth-1 {
  animation-timing-function: linear;
  animation: mouth-1 5s infinite;
}
@media only screen and (max-width: 480px) {
  /* line 142, ../sass/partials/_animations.scss */
  .mouth-1 {
    animation: none;
  }
}

/* line 150, ../sass/partials/_animations.scss */
.mouth-2 {
  animation-timing-function: linear;
  animation: mouth-2 5s infinite;
}
@media only screen and (max-width: 480px) {
  /* line 150, ../sass/partials/_animations.scss */
  .mouth-2 {
    animation: none;
    display: none;
  }
}

@keyframes mouth-2 {
  0% {
    opacity: 0;
  }
  79% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes mouth-1 {
  0% {
    opacity: 1;
  }
  79% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* line 179, ../sass/partials/_animations.scss */
.arm {
  animation-timing-function: linear;
  animation: arm-move 4s infinite;
  transform-origin: -204px 118px;
  transform: translate(234px, 105px) rotate(-34deg);
}

@keyframes arm-move {
  0% {
    transform: translate(234px, 105px) rotate(-34deg);
  }
  50% {
    transform: translate(234px, 105px) rotate(21deg);
  }
  100% {
    transform: translate(234px, 105px) rotate(-34deg);
  }
}
/* line 192, ../sass/partials/_animations.scss */
.hand {
  transform-origin: 66px 144px;
  transform: translate(-7px, 1px) rotate(50deg);
  animation-timing-function: linear;
  animation: hammer-move 4s infinite;
}

@keyframes hammer-move {
  0% {
    transform: translate(-7px, 1px) rotate(50deg);
  }
  25% {
    transform: translate(-7px, 1px) rotate(50deg);
  }
  50% {
    transform: translate(0px, -8px) rotate(100deg);
  }
  100% {
    transform: translate(-7px, 1px) rotate(50deg);
  }
}
/* line 206, ../sass/partials/_animations.scss */
.eye-move-1 {
  animation-timing-function: linear;
  animation: eye-move-1 4s infinite;
  opacity: 1;
}

/* line 212, ../sass/partials/_animations.scss */
.eye-move-2 {
  animation-timing-function: linear;
  animation: eye-move-2 4s infinite;
  opacity: 0;
}

/* line 218, ../sass/partials/_animations.scss */
.eye-move-3 {
  animation-timing-function: linear;
  animation: eye-move-3 4s infinite;
  opacity: 0;
}

@keyframes eye-move-1 {
  0% {
    opacity: 0;
  }
  32% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes eye-move-2 {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  32% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes eye-move-3 {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* line 248, ../sass/partials/_animations.scss */
.robot-level-1 {
  transform-origin: 72px 139px;
  animation-timing-function: linear;
  animation: robot-level-1 2s infinite;
}

@keyframes robot-level-1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* line 260, ../sass/partials/_animations.scss */
.robot-level-2 {
  transform-origin: 27px 96px;
  animation-timing-function: linear;
  animation: robot-level-2 5s infinite;
}

@keyframes robot-level-2 {
  0% {
    transform: rotate(-34deg);
  }
  50% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(-34deg);
  }
}
/* line 272, ../sass/partials/_animations.scss */
.robot-level-3 {
  transform-origin: 62px 44px;
  animation-timing-function: linear;
  animation: robot-level-3 8s infinite;
}

@keyframes robot-level-3 {
  0% {
    transform: rotate(-34deg);
  }
  50% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(-34deg);
  }
}
/* line 284, ../sass/partials/_animations.scss */
.robot-level-4 {
  transform-origin: 140px 27px;
  animation-timing-function: linear;
  animation: robot-level-4 10s infinite;
}

@keyframes robot-level-4 {
  0% {
    transform: rotate(-34deg);
  }
  50% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(-34deg);
  }
}
/*
 *  EXPERIENCE
 */
/* line 300, ../sass/partials/_animations.scss */
.cape-1 {
  animation-timing-function: linear;
  animation: cape-1 0.5s infinite;
}

@keyframes cape-1 {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* line 313, ../sass/partials/_animations.scss */
.cape-2 {
  animation-timing-function: linear;
  animation: cape-2 0.5s infinite;
}

@keyframes cape-2 {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* line 326, ../sass/partials/_animations.scss */
.cloud-1 {
  transform: translate(-1000px, 0px);
  animation-timing-function: linear;
  animation: cloud-1 10s infinite;
}

@keyframes cloud-1 {
  0% {
    transform: translate(-1000px, 0px);
  }
  100% {
    transform: translate(2000px, 0px);
  }
}
/* line 337, ../sass/partials/_animations.scss */
.cloud-2 {
  opacity: 0.9;
  transform: translate(-1000px, 200px);
  animation-timing-function: linear;
  animation: cloud-2 15s infinite;
}

@keyframes cloud-2 {
  0% {
    transform: translate(-1000px, 200px);
  }
  100% {
    transform: translate(2000px, 200px);
  }
}
/* line 349, ../sass/partials/_animations.scss */
.cloud-3 {
  transform: translate(-1000px, 400px);
  animation-timing-function: linear;
  animation: cloud-3 5s infinite;
}

@keyframes cloud-3 {
  0% {
    transform: translate(-1000px, 400px);
  }
  100% {
    transform: translate(2000px, 400px);
  }
}
/* line 360, ../sass/partials/_animations.scss */
.weilding-hand {
  animation-timing-function: linear;
  animation: weilding-hand 5s infinite;
  transform-origin: 242px 146px;
}

@keyframes weilding-hand {
  0% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(32deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* line 373, ../sass/partials/_animations.scss */
.weilding-spark {
  animation-timing-function: linear;
  animation: weilding-spark 5s infinite;
}

@keyframes weilding-spark {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  71% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/* line 385, ../sass/partials/_animations.scss */
.weilding-spark-1 {
  animation-timing-function: linear;
  animation: weilding-spark-1 0.1s infinite;
}

@keyframes weilding-spark-1 {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/* line 397, ../sass/partials/_animations.scss */
.weilding-spark-2 {
  animation-timing-function: linear;
  animation: weilding-spark-2 0.1s infinite;
}

@keyframes weilding-spark-2 {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/* line 409, ../sass/partials/_animations.scss */
.headset {
  transform: translate(0px, -1px);
  transform-origin: 50% 50%;
  animation-timing-function: linear;
  animation: headset 2s infinite;
}

@keyframes headset {
  0% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  76% {
    transform: rotate(10deg);
  }
  82% {
    transform: rotate(-10deg);
  }
  88% {
    transform: rotate(10deg);
  }
  94% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* line 426, ../sass/partials/_animations.scss */
.vibrate-1 {
  animation-timing-function: linear;
  animation: vibrate 2s infinite;
}

/* line 431, ../sass/partials/_animations.scss */
.vibrate-2 {
  animation-timing-function: linear;
  animation: vibrate 1.5s infinite;
}

/* line 436, ../sass/partials/_animations.scss */
.vibrate-3 {
  animation-timing-function: linear;
  animation: vibrate 1s infinite;
}

@keyframes vibrate {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/* line 449, ../sass/partials/_animations.scss */
.get-in-touch-banner {
  animation-timing-function: linear;
  animation: banner 4s infinite;
}

@keyframes banner {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(0px, -10px);
  }
  50% {
    transform: translate(0px, 0px);
  }
}
/* line 1, ../sass/partials/_global.scss */
* {
  box-sizing: border-box;
}

/* line 5, ../sass/partials/_global.scss */
html, body {
  height: 100%;
  min-width: 320px;
  background-color: white;
}

/* line 11, ../sass/partials/_global.scss */
body {
  font-family: "comic-relief", sans-serif;
  font-size: 100%;
}

/* line 16, ../sass/partials/_global.scss */
a:link, a:visited {
  color: #FFD700;
}

/* line 20, ../sass/partials/_global.scss */
a:hover {
  cursor: pointer;
}

/* line 24, ../sass/partials/_global.scss */
.content {
  overflow: hidden;
  border-radius: 3px;
  margin: 0.5% 0.5% 0px 0.5%;
}
@media only screen and (max-width: 480px) {
  /* line 24, ../sass/partials/_global.scss */
  .content {
    margin: 5px 5px 0px 5px;
  }
}

/* line 35, ../sass/partials/_global.scss */
.multiple-content {
  border-radius: 3px;
  margin: 0.5% 0px 0px 0.5%;
}
@media only screen and (max-width: 480px) {
  /* line 35, ../sass/partials/_global.scss */
  .multiple-content {
    margin: 5px 5px 0px 5px;
  }
}

/* line 45, ../sass/partials/_global.scss */
.hide {
  display: none;
}

/* line 49, ../sass/partials/_global.scss */
.show {
  display: block;
}

/* line 1, ../sass/partials/_pre-loader.scss */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  left: 123px;
}

/* line 8, ../sass/partials/_pre-loader.scss */
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

/* line 12, ../sass/partials/_pre-loader.scss */
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

/* line 22, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

/* line 25, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

/* line 29, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

/* line 32, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

/* line 36, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

/* line 39, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

/* line 43, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

/* line 46, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

/* line 50, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

/* line 53, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

/* line 57, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

/* line 60, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

/* line 64, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

/* line 67, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

/* line 71, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

/* line 74, ../sass/partials/_pre-loader.scss */
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* line 1, ../sass/partials/_header.scss */
header {
  width: 100%;
  background-color: white;
  box-shadow: 5px 5px 10px gray;
  position: fixed;
  z-index: 5;
  padding: 0px 0px 5px 5px;
}
@media only screen and (max-width: 1000px) {
  /* line 1, ../sass/partials/_header.scss */
  header {
    padding: 0px;
  }
}
/* line 11, ../sass/partials/_header.scss */
header .logo {
  color: #FFD700;
  font-weight: 300;
  font-size: 200%;
  width: 30%;
  max-width: 400px;
  float: left;
}
@media only screen and (max-width: 1000px) {
  /* line 11, ../sass/partials/_header.scss */
  header .logo {
    position: absolute;
    z-index: 5;
    left: 5px;
    width: 50%;
  }
}
/* line 25, ../sass/partials/_header.scss */
header .logo svg {
  width: 100%;
}
/* line 32, ../sass/partials/_header.scss */
header .logo a:hover svg g path {
  box-shadow: 5px 5px gray;
}
/* line 42, ../sass/partials/_header.scss */
header .blank-div {
  width: 100%;
  height: 50px;
  background-color: white;
  position: absolute;
  z-index: 4;
  display: none;
}
@media only screen and (max-width: 1000px) {
  /* line 42, ../sass/partials/_header.scss */
  header .blank-div {
    display: block;
  }
}
/* line 53, ../sass/partials/_header.scss */
header nav {
  padding-top: 10px;
  float: right;
}
/* line 57, ../sass/partials/_header.scss */
header nav .menu-button {
  height: 30px;
  width: 30px;
  display: none;
  position: relative;
  z-index: 5;
  float: right;
}
/* line 64, ../sass/partials/_header.scss */
header nav .menu-button .ham {
  width: 22px;
  height: 5px;
  background-color: #FFD700;
  margin: auto;
  position: absolute;
  top: 14px;
  left: 4px;
  border: 1px solid black;
  border-radius: 5px;
}
/* line 74, ../sass/partials/_header.scss */
header nav .menu-button .ham::before, header nav .menu-button .ham::after {
  content: '';
  position: absolute;
  border: 1px solid black;
  width: 22px;
  height: 5px;
  border-radius: 15px;
  background-color: #FFD700;
}
/* line 83, ../sass/partials/_header.scss */
header nav .menu-button .ham::before {
  right: -1px;
  bottom: 7px;
}
/* line 87, ../sass/partials/_header.scss */
header nav .menu-button .ham::after {
  right: -1px;
  top: 7px;
}
@media only screen and (max-width: 1000px) {
  /* line 57, ../sass/partials/_header.scss */
  header nav .menu-button {
    display: block;
  }
}
/* line 95, ../sass/partials/_header.scss */
header nav .menu-button .cross {
  background-color: transparent;
  border: 1px solid transparent;
}
/* line 98, ../sass/partials/_header.scss */
header nav .menu-button .cross::before {
  -webkit-animation-name: before;
  animation-name: before;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-transform: translate(0px, 8px) rotate(45deg);
  transform: translate(0px, 8px) rotate(45deg);
}
/* line 106, ../sass/partials/_header.scss */
header nav .menu-button .cross::after {
  -webkit-animation-name: after;
  animation-name: after;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-transform: translate(0px, -8px) rotate(-45deg);
  transform: translate(0px, -8px) rotate(-45deg);
}
@-webkit-keyframes before {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  25% {
    -webkit-transform: translate(0px, 8px) rotate(0deg);
    transform: translate(0px, 8px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(0px, 8px) rotate(45deg);
    transform: translate(0px, 8px) rotate(45deg);
  }
}
@keyframes before {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  25% {
    -webkit-transform: translate(0px, 8px) rotate(0deg);
    transform: translate(0px, 8px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(0px, 8px) rotate(45deg);
    transform: translate(0px, 8px) rotate(45deg);
  }
}
@-webkit-keyframes after {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  25% {
    -webkit-transform: translate(0px, -8px) rotate(0deg);
    transform: translate(0px, -8px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(0px, -8px) rotate(-45deg);
    transform: translate(0px, -8px) rotate(-45deg);
  }
}
@keyframes after {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  25% {
    -webkit-transform: translate(0px, -8px) rotate(0deg);
    transform: translate(0px, -8px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(0px, -8px) rotate(-45deg);
    transform: translate(0px, -8px) rotate(-45deg);
  }
}
/* line 135, ../sass/partials/_header.scss */
header nav .menu-button:hover {
  cursor: pointer;
}
/* line 140, ../sass/partials/_header.scss */
header nav .menu-items {
  font-family: "Kalam", cursive;
  padding: 0px;
  margin: 0px;
}
/* line 146, ../sass/partials/_header.scss */
header nav .menu-items .menu-item {
  display: inline-block;
  font-size: 120%;
}
/* line 149, ../sass/partials/_header.scss */
header nav .menu-items .menu-item .menu-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  color: black;
  font-weight: bolder;
  -webkit-text-fill-color: #FFD700;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}
/* line 161, ../sass/partials/_header.scss */
header nav .menu-items .menu-item .menu-link:hover {
  text-decoration: none;
  cursor: pointer;
  color: red;
}
@media only screen and (max-width: 1000px) {
  /* line 149, ../sass/partials/_header.scss */
  header nav .menu-items .menu-item .menu-link {
    padding: 10px 10px;
  }
}
@media only screen and (max-width: 1000px) {
  /* line 146, ../sass/partials/_header.scss */
  header nav .menu-items .menu-item {
    display: block;
    background-color: white;
  }
}
@media only screen and (max-width: 1000px) {
  /* line 140, ../sass/partials/_header.scss */
  header nav .menu-items {
    display: block;
    position: absolute;
    z-index: 1;
    height: 240px;
    top: -215px;
    box-shadow: 0px 0px 90px gray;
    right: -5px;
    width: 250px;
    transition: 0.5s linear all;
  }
}
/* line 188, ../sass/partials/_header.scss */
header nav .expand {
  display: block;
  top: 45px;
  right: -5px;
}

/* line 1, ../sass/partials/_footer.scss */
footer {
  background-color: black;
  width: 100%;
  margin-top: 0.5%;
  padding: 2%;
}
@media only screen and (max-width: 480px) {
  /* line 1, ../sass/partials/_footer.scss */
  footer {
    margin-top: 1%;
  }
}
/* line 9, ../sass/partials/_footer.scss */
footer .social-links-container {
  width: 100%;
}
/* line 11, ../sass/partials/_footer.scss */
footer .social-links-container .social-links {
  max-width: 375px;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  /* line 11, ../sass/partials/_footer.scss */
  footer .social-links-container .social-links {
    width: 250px;
  }
}
@media only screen and (max-width: 480px) {
  /* line 11, ../sass/partials/_footer.scss */
  footer .social-links-container .social-links {
    width: 160px;
  }
}
/* line 20, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link {
  border-radius: 50%;
  width: 80px;
  margin-right: 10px;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  /* line 20, ../sass/partials/_footer.scss */
  footer .social-links-container .social-links .social-link {
    width: 70px;
  }
}
@media only screen and (max-width: 480px) {
  /* line 20, ../sass/partials/_footer.scss */
  footer .social-links-container .social-links .social-link {
    width: 40px;
  }
}
/* line 31, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link svg {
  width: 100%;
}
/* line 34, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover {
  cursor: pointer;
}
/* line 36, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.twitter {
  background-color: #1da1f2;
}
/* line 39, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.twitter svg path {
  fill: black;
}
/* line 44, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.linked-in {
  background-color: #2c82c9;
}
/* line 47, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.linked-in svg path {
  fill: black;
}
/* line 52, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.youtube {
  background-color: #ff2a2a;
}
/* line 55, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.youtube svg path {
  fill: black;
}
/* line 58, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.youtube svg .youtube-inner {
  fill: #ff2a2a;
}
/* line 63, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.github {
  background-color: white;
}
/* line 66, ../sass/partials/_footer.scss */
footer .social-links-container .social-links .social-link:hover.github svg path {
  fill: black;
}
/* line 75, ../sass/partials/_footer.scss */
footer .footer-description {
  text-align: center;
  color: white;
  margin: 0px;
}

/* line 2, ../sass/partials/_home.scss */
.home .content {
  width: 100%;
  background-color: #00c2ff;
  background: linear-gradient(90deg, #3a47d5 -71%, #00d2ff 49%, #3a47d5 220%);
}
/* line 8, ../sass/partials/_home.scss */
.home .content .character-home {
  width: 50%;
  float: left;
}
/* line 11, ../sass/partials/_home.scss */
.home .content .character-home svg {
  float: right;
  height: auto;
  width: 90%;
}
/* line 17, ../sass/partials/_home.scss */
.home .content .speech-bubble-home {
  width: 50%;
  float: right;
}
/* line 20, ../sass/partials/_home.scss */
.home .content .speech-bubble-home svg {
  float: left;
  height: auto;
  width: 90%;
}
/* line 24, ../sass/partials/_home.scss */
.home .content .speech-bubble-home svg text {
  font-size: 65%;
}

/* line 2, ../sass/partials/_about.scss */
.about .content-about-0 {
  width: 100%;
  background: #f39c12;
  /* fallback for old browsers */
  background: linear-gradient(90deg, #f39c12 -71%, #f1c40f 49%, #f39c12 220%);
}
/* line 7, ../sass/partials/_about.scss */
.about .content-about-0 .character-about-0 {
  width: 30%;
  float: right;
}
/* line 10, ../sass/partials/_about.scss */
.about .content-about-0 .character-about-0 svg {
  float: left;
  height: auto;
  width: 90%;
}
/* line 16, ../sass/partials/_about.scss */
.about .content-about-0 .speech-bubble-about-0 {
  width: 50%;
  float: left;
}
/* line 19, ../sass/partials/_about.scss */
.about .content-about-0 .speech-bubble-about-0 svg {
  float: right;
  height: auto;
  width: 80%;
}
/* line 26, ../sass/partials/_about.scss */
.about .content-about-1 {
  width: 49.5%;
  height: 100%;
  background-color: #27ae60;
  background: linear-gradient(90deg, #196b3c -71%, #2ecc71 49%, #196b3c 220%);
}
@media only screen and (max-width: 480px) {
  /* line 26, ../sass/partials/_about.scss */
  .about .content-about-1 {
    width: 100%;
  }
}
/* line 34, ../sass/partials/_about.scss */
.about .content-about-1 .character-about-1 {
  width: 30%;
  float: left;
}
/* line 37, ../sass/partials/_about.scss */
.about .content-about-1 .character-about-1 svg {
  float: left;
  height: auto;
  width: 90%;
}
/* line 44, ../sass/partials/_about.scss */
.about .content-about-1 .speech-bubble-about-1 {
  width: 70%;
  float: left;
}
/* line 47, ../sass/partials/_about.scss */
.about .content-about-1 .speech-bubble-about-1 svg {
  float: right;
  height: auto;
  width: 100%;
}
/* line 51, ../sass/partials/_about.scss */
.about .content-about-1 .speech-bubble-about-1 svg text {
  font-size: 48%;
}
/* line 57, ../sass/partials/_about.scss */
.about .content-about-2 {
  width: 49%;
  height: auto;
  display: flex;
  justify-content: space-between;
  background-color: #e74c3c;
  background: linear-gradient(90deg, #c0392b 9%, #e74c3c 54%, #c0392b 147%);
}
@media only screen and (max-width: 480px) {
  /* line 57, ../sass/partials/_about.scss */
  .about .content-about-2 {
    width: 100%;
  }
}
/* line 68, ../sass/partials/_about.scss */
.about .content-about-2 .character-about-2 {
  width: 40%;
  float: left;
  align-self: flex-end;
}
/* line 72, ../sass/partials/_about.scss */
.about .content-about-2 .character-about-2 svg {
  float: left;
  height: auto;
  width: 100%;
}
/* line 79, ../sass/partials/_about.scss */
.about .content-about-2 .speech-bubble-about-2 {
  width: 50%;
  float: right;
  align-self: center;
}
/* line 83, ../sass/partials/_about.scss */
.about .content-about-2 .speech-bubble-about-2 svg {
  float: right;
  height: auto;
  width: 100%;
}
/* line 87, ../sass/partials/_about.scss */
.about .content-about-2 .speech-bubble-about-2 svg text {
  font-size: 70%;
}
/* line 93, ../sass/partials/_about.scss */
.about .content-about-3 {
  width: 49.5%;
  height: auto;
  display: flex;
  background-color: #2980b9;
  background: linear-gradient(90deg, #023352 -71%, #3498db 49%, #023352 220%);
}
@media only screen and (max-width: 480px) {
  /* line 93, ../sass/partials/_about.scss */
  .about .content-about-3 {
    width: 100%;
  }
}
/* line 103, ../sass/partials/_about.scss */
.about .content-about-3 .character-about-3 {
  width: 40%;
  float: left;
  align-self: flex-end;
}
/* line 107, ../sass/partials/_about.scss */
.about .content-about-3 .character-about-3 svg {
  float: left;
  height: auto;
  width: 100%;
}
/* line 114, ../sass/partials/_about.scss */
.about .content-about-3 .speech-bubble-about-3 {
  width: 60%;
  float: left;
  align-self: center;
}
/* line 118, ../sass/partials/_about.scss */
.about .content-about-3 .speech-bubble-about-3 svg {
  float: right;
  height: auto;
  width: 100%;
}
/* line 122, ../sass/partials/_about.scss */
.about .content-about-3 .speech-bubble-about-3 svg text {
  font-size: 60%;
}
/* line 128, ../sass/partials/_about.scss */
.about .content-about-4 {
  width: 49%;
  position: relative;
  background-color: #8e44ad;
  background: linear-gradient(90deg, #050306 -71%, #3d2348 49%, #4a245a 220%);
}
@media only screen and (max-width: 480px) {
  /* line 128, ../sass/partials/_about.scss */
  .about .content-about-4 {
    width: 100%;
  }
}
/* line 137, ../sass/partials/_about.scss */
.about .content-about-4 .character-about-4 {
  width: 40%;
  float: left;
}
/* line 142, ../sass/partials/_about.scss */
.about .content-about-4 .character-about-4 svg {
  float: left;
  height: auto;
  width: 70%;
}
/* line 148, ../sass/partials/_about.scss */
.about .content-about-4 .character-about-4-a {
  width: 60%;
  float: right;
}
/* line 153, ../sass/partials/_about.scss */
.about .content-about-4 .character-about-4-a svg {
  float: left;
  height: auto;
  width: 100%;
}
/* line 157, ../sass/partials/_about.scss */
.about .content-about-4 .character-about-4-a svg text {
  font-size: 70%;
}
/* line 163, ../sass/partials/_about.scss */
.about .content-about-5 {
  width: 100%;
  background-color: #009432;
  background: linear-gradient(90deg, #02501c -71%, #7ec708 49%, #02501c 220%);
}
/* line 167, ../sass/partials/_about.scss */
.about .content-about-5 .speech-box {
  width: 98%;
  margin: 1% 1%;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  font-size: 125%;
}
@media only screen and (max-width: 767px) {
  /* line 167, ../sass/partials/_about.scss */
  .about .content-about-5 .speech-box {
    font-size: 100%;
  }
}
/* line 179, ../sass/partials/_about.scss */
.about .content-about-5 svg {
  padding: 1px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

/* line 2, ../sass/partials/_experience.scss */
.experience .content-experience-0 {
  width: 100%;
  background-color: #00c2ff;
}
/* line 6, ../sass/partials/_experience.scss */
.experience .content-experience-0 .character-experience-0 {
  width: 30%;
  float: right;
}
/* line 9, ../sass/partials/_experience.scss */
.experience .content-experience-0 .character-experience-0 svg {
  float: left;
  height: auto;
  width: 90%;
}

/* line 18, ../sass/partials/_experience.scss */
.content-experience-1 {
  width: 100%;
  background-color: #ececec;
  background: linear-gradient(90deg, #bdc3c7 -71%, #ececec 49%, #bdc3c7 220%);
  display: flex;
  justify-content: space-between;
}
/* line 24, ../sass/partials/_experience.scss */
.content-experience-1 .character-experience-1 {
  width: 55%;
  display: flex;
}
/* line 27, ../sass/partials/_experience.scss */
.content-experience-1 .character-experience-1 svg {
  width: 100%;
  padding: 5%;
}
/* line 34, ../sass/partials/_experience.scss */
.content-experience-1 .speech-bubble-experience-1 {
  width: 45%;
  background-color: white;
  border-radius: 5px;
  margin: 1%;
  padding: 7% 3%;
  text-align: center;
  display: flex;
}
/* line 42, ../sass/partials/_experience.scss */
.content-experience-1 .speech-bubble-experience-1 div {
  justify-self: center;
  align-self: center;
  margin: auto;
  font-size: 2.0vw;
}
@media only screen and (max-width: 480px) {
  /* line 42, ../sass/partials/_experience.scss */
  .content-experience-1 .speech-bubble-experience-1 div {
    font-size: 2.5vw;
  }
}
/* line 50, ../sass/partials/_experience.scss */
.content-experience-1 .speech-bubble-experience-1 div span {
  font-weight: bolder;
}
/* line 52, ../sass/partials/_experience.scss */
.content-experience-1 .speech-bubble-experience-1 div span a {
  color: #22a7f0;
}
/* line 54, ../sass/partials/_experience.scss */
.content-experience-1 .speech-bubble-experience-1 div span a:hover {
  color: #1f3a93;
  font-weight: bolder;
}

/* line 64, ../sass/partials/_experience.scss */
.content-experience-2 {
  width: 100%;
  background-color: #ffec8b;
  background: linear-gradient(90deg, #e9d460 -71%, #ffec8b 49%, #e9d460 220%);
  display: flex;
  justify-content: space-between;
}
/* line 70, ../sass/partials/_experience.scss */
.content-experience-2 .character-experience-2 {
  width: 55%;
  display: flex;
}
/* line 73, ../sass/partials/_experience.scss */
.content-experience-2 .character-experience-2 svg {
  width: 100%;
  padding: 5%;
}
/* line 80, ../sass/partials/_experience.scss */
.content-experience-2 .speech-bubble-experience-2 {
  width: 45%;
  background-color: white;
  border-radius: 5px;
  margin: 1%;
  padding: 7% 3%;
  text-align: center;
  display: flex;
}
/* line 88, ../sass/partials/_experience.scss */
.content-experience-2 .speech-bubble-experience-2 div {
  justify-self: center;
  align-self: center;
  margin: auto;
  font-size: 2.0vw;
}
@media only screen and (max-width: 480px) {
  /* line 88, ../sass/partials/_experience.scss */
  .content-experience-2 .speech-bubble-experience-2 div {
    font-size: 2.5vw;
  }
}
/* line 96, ../sass/partials/_experience.scss */
.content-experience-2 .speech-bubble-experience-2 div span {
  font-weight: bolder;
}
/* line 98, ../sass/partials/_experience.scss */
.content-experience-2 .speech-bubble-experience-2 div span a {
  color: #22a7f0;
}
/* line 100, ../sass/partials/_experience.scss */
.content-experience-2 .speech-bubble-experience-2 div span a:hover {
  color: #1f3a93;
  font-weight: bolder;
}

/* line 110, ../sass/partials/_experience.scss */
.content-experience-3 {
  width: 100%;
  background-color: #be90d4;
  background: linear-gradient(90deg, #674172 -71%, #be90d4 49%, #674172 220%);
  display: flex;
  justify-content: space-between;
}
/* line 116, ../sass/partials/_experience.scss */
.content-experience-3 .character-experience-3 {
  width: 55%;
  display: flex;
}
/* line 119, ../sass/partials/_experience.scss */
.content-experience-3 .character-experience-3 svg {
  width: 100%;
  padding: 10%;
}
/* line 126, ../sass/partials/_experience.scss */
.content-experience-3 .speech-bubble-experience-3 {
  width: 45%;
  background-color: white;
  border-radius: 5px;
  margin: 1%;
  padding: 7% 3%;
  text-align: center;
  display: flex;
}
/* line 134, ../sass/partials/_experience.scss */
.content-experience-3 .speech-bubble-experience-3 div {
  justify-self: center;
  align-self: center;
  margin: auto;
  font-size: 2.0vw;
}
@media only screen and (max-width: 480px) {
  /* line 134, ../sass/partials/_experience.scss */
  .content-experience-3 .speech-bubble-experience-3 div {
    font-size: 2.5vw;
  }
}
/* line 142, ../sass/partials/_experience.scss */
.content-experience-3 .speech-bubble-experience-3 div span {
  font-weight: bolder;
}
/* line 144, ../sass/partials/_experience.scss */
.content-experience-3 .speech-bubble-experience-3 div span a {
  color: #22a7f0;
}
/* line 146, ../sass/partials/_experience.scss */
.content-experience-3 .speech-bubble-experience-3 div span a:hover {
  color: #1f3a93;
  font-weight: bolder;
}

/* line 156, ../sass/partials/_experience.scss */
#experienceModal {
  background-color: rgba(0, 0, 0, 0.8);
}
/* line 159, ../sass/partials/_experience.scss */
#experienceModal .modal-header .modal-header-container {
  display: block;
  width: 100%;
}
/* line 162, ../sass/partials/_experience.scss */
#experienceModal .modal-header .modal-header-container .org-icon {
  display: block;
  width: 50%;
  margin: auto;
}
/* line 166, ../sass/partials/_experience.scss */
#experienceModal .modal-header .modal-header-container .org-icon svg {
  width: 100%;
}
/* line 170, ../sass/partials/_experience.scss */
#experienceModal .modal-header .modal-header-container .org-details {
  display: block;
  width: 100%;
  text-align: center;
}
/* line 176, ../sass/partials/_experience.scss */
#experienceModal .modal-header .modal-header-container .links:hover {
  font-weight: bolder;
  text-decoration: none;
}
/* line 180, ../sass/partials/_experience.scss */
#experienceModal .modal-header .modal-header-container .links.website {
  color: #f22613;
}
/* line 183, ../sass/partials/_experience.scss */
#experienceModal .modal-header .modal-header-container .links.linked-in {
  color: #22a7f0;
}
/* line 186, ../sass/partials/_experience.scss */
#experienceModal .modal-header .modal-header-container .links.twitter {
  color: #19b5fe;
}
/* line 192, ../sass/partials/_experience.scss */
#experienceModal .modal-body {
  text-align: center;
}

/* line 2, ../sass/partials/_skills.scss */
.skills .content-skills-0 {
  width: 100%;
  background-color: #00c2ff;
  display: flex;
}
/* line 6, ../sass/partials/_skills.scss */
.skills .content-skills-0 .speech-bubble-skills-0 {
  width: 50%;
  float: left;
  align-self: center;
  justify-self: center;
}
/* line 11, ../sass/partials/_skills.scss */
.skills .content-skills-0 .speech-bubble-skills-0 svg {
  float: right;
  height: auto;
  width: 100%;
}
/* line 15, ../sass/partials/_skills.scss */
.skills .content-skills-0 .speech-bubble-skills-0 svg text {
  font-size: 70%;
  font-family: "Caveat Brush", cursive;
}
/* line 22, ../sass/partials/_skills.scss */
.skills .content-skills-0 .character-skills-0 {
  width: 50%;
  float: right;
}
/* line 25, ../sass/partials/_skills.scss */
.skills .content-skills-0 .character-skills-0 svg {
  float: left;
  height: auto;
  width: 90%;
}

/* line 34, ../sass/partials/_skills.scss */
.skills-box {
  width: 19.4%;
  position: relative;
}
@media only screen and (max-width: 480px) {
  /* line 34, ../sass/partials/_skills.scss */
  .skills-box {
    width: 23.74%;
    margin: 1% 0% 0% 1%;
  }
}
/* line 41, ../sass/partials/_skills.scss */
.skills-box svg {
  width: 100%;
}
/* line 44, ../sass/partials/_skills.scss */
.skills-box:hover {
  cursor: pointer;
}

/* line 49, ../sass/partials/_skills.scss */
.skill-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
  background: black;
  background: rgba(0, 0, 0, 0.8);
  font-size: 0%;
}
/* line 61, ../sass/partials/_skills.scss */
.skill-overlay p {
  position: absolute;
  bottom: 0px;
  color: white;
  font-weight: bolder;
}
/* line 67, ../sass/partials/_skills.scss */
.skill-overlay:hover {
  cursor: pointer;
}

/* line 72, ../sass/partials/_skills.scss */
.skill-col-1 {
  background: #1e90ff;
}

/* line 76, ../sass/partials/_skills.scss */
.skill-col-2 {
  background: #4b4b40;
}

/* line 80, ../sass/partials/_skills.scss */
.skill-col-3 {
  background: #3B3B98;
}

/* line 84, ../sass/partials/_skills.scss */
.skill-col-4 {
  background: #7bed9f;
}

/* line 88, ../sass/partials/_skills.scss */
.skill-col-5 {
  background: #2f3542;
}

/* line 92, ../sass/partials/_skills.scss */
.skill-col-6 {
  background: #ced6e0;
}

/* line 96, ../sass/partials/_skills.scss */
.skill-col-7 {
  background: #eccc68;
}

/* line 100, ../sass/partials/_skills.scss */
.skill-col-8 {
  background: #ff7f50;
}

/* line 104, ../sass/partials/_skills.scss */
.skill-col-9 {
  background: #ffeaa7;
}

/* line 108, ../sass/partials/_skills.scss */
.skill-col-10 {
  background: #fdcb6e;
}

/* line 112, ../sass/partials/_skills.scss */
.skill-col-11 {
  background: #00c2ff;
}

/* line 116, ../sass/partials/_skills.scss */
.skill-col-12 {
  background: #55efc4;
}

/* line 120, ../sass/partials/_skills.scss */
.skill-col-13 {
  background: #e17055;
}

/* line 124, ../sass/partials/_skills.scss */
.skill-col-14 {
  background: #f9ca24;
}

/* line 128, ../sass/partials/_skills.scss */
.skill-col-15 {
  background: #686de0;
}

/* line 132, ../sass/partials/_skills.scss */
#skillModal {
  background-color: rgba(0, 0, 0, 0.8);
}
/* line 135, ../sass/partials/_skills.scss */
#skillModal .modal-header .modal-skill-icon {
  width: 30%;
  padding: 2px;
}
/* line 139, ../sass/partials/_skills.scss */
#skillModal .modal-header .modal-skill {
  padding: 5px;
  width: 70%;
  text-align: center;
}
/* line 143, ../sass/partials/_skills.scss */
#skillModal .modal-header .modal-skill .progress-bar {
  background-color: #ccc8c8;
  height: 30px;
  width: 100%;
  border-radius: 15px;
  position: relative;
}
/* line 149, ../sass/partials/_skills.scss */
#skillModal .modal-header .modal-skill .progress-bar .actual-progress {
  width: 0%;
  position: absolute;
  height: 20px;
  background-color: #1eaf27;
  border-radius: 10px;
  left: 7px;
}
/* line 160, ../sass/partials/_skills.scss */
#skillModal .modal-body {
  text-align: center;
}

/* line 2, ../sass/partials/_contact.scss */
.contact .content-contact {
  width: 100%;
  background-color: #89c4f4;
  background: linear-gradient(90deg, #2c82c9 -71%, #89c4f4 49%, #2c82c9 220%);
  display: flex;
}
/* line 7, ../sass/partials/_contact.scss */
.contact .content-contact .character-contact {
  width: 50%;
  float: left;
}
/* line 11, ../sass/partials/_contact.scss */
.contact .content-contact .speech-bubble-contact {
  width: 50%;
  float: right;
  display: flex;
}
/* line 15, ../sass/partials/_contact.scss */
.contact .content-contact .speech-bubble-contact svg {
  justify-self: center;
  align-self: center;
  width: 70%;
  margin: auto;
}
/* line 24, ../sass/partials/_contact.scss */
.contact .contact-form {
  width: 100%;
  background-color: #e8ecf1;
  background: linear-gradient(90deg, #abb7b7 -71%, #e8ecf1 49%, #abb7b7 220%);
  display: flex;
}
/* line 29, ../sass/partials/_contact.scss */
.contact .contact-form .form {
  width: 50%;
  float: right;
  margin: auto;
  padding: 10px;
}
/* line 34, ../sass/partials/_contact.scss */
.contact .contact-form .form textarea {
  resize: none;
}
/* line 37, ../sass/partials/_contact.scss */
.contact .contact-form .form .contact-input {
  margin-bottom: 10px;
}
@media only screen and (max-width: 667px) {
  /* line 29, ../sass/partials/_contact.scss */
  .contact .contact-form .form {
    width: 100%;
  }
}
/* line 43, ../sass/partials/_contact.scss */
.contact .contact-form .form #errors {
  margin-bottom: 10px;
  color: red;
}
/* line 47, ../sass/partials/_contact.scss */
.contact .contact-form .form button {
  margin-top: 10px;
}
/* line 51, ../sass/partials/_contact.scss */
.contact .contact-form .contact-form-character {
  float: left;
  width: 50%;
  align-self: flex-end;
}
/* line 55, ../sass/partials/_contact.scss */
.contact .contact-form .contact-form-character svg {
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
}
@media only screen and (max-width: 667px) {
  /* line 51, ../sass/partials/_contact.scss */
  .contact .contact-form .contact-form-character {
    display: none;
  }
}
/* line 66, ../sass/partials/_contact.scss */
.contact #contactModal {
  background-color: rgba(0, 0, 0, 0.8);
}
/* line 71, ../sass/partials/_contact.scss */
.contact #contactModal .modal-body {
  text-align: center;
}

/* line 2, ../sass/partials/_works.scss */
.works .content-works-0 {
  width: 100%;
  background-color: #00c2ff;
  background: #FF8008;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFC837, #FF8008);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFC837, #FF8008);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  padding-top: 10px;
}
/* line 11, ../sass/partials/_works.scss */
.works .content-works-0 .character-works-0 {
  width: 50%;
  float: right;
  align-self: flex-end;
}
/* line 15, ../sass/partials/_works.scss */
.works .content-works-0 .character-works-0 svg {
  float: left;
  height: auto;
  width: 90%;
}
/* line 21, ../sass/partials/_works.scss */
.works .content-works-0 .speech-bubble-works-0 {
  width: 50%;
  float: left;
  align-self: center;
  justify-self: center;
}
/* line 26, ../sass/partials/_works.scss */
.works .content-works-0 .speech-bubble-works-0 svg {
  float: right;
  height: auto;
  width: 95%;
}
/* line 30, ../sass/partials/_works.scss */
.works .content-works-0 .speech-bubble-works-0 svg text {
  font-size: 80%;
  font-family: "Caveat Brush", cursive;
}
/* line 38, ../sass/partials/_works.scss */
.works .content-works {
  width: 32.66%;
  display: flex;
  justify-content: center;
  transition: background-color 0.8s;
}
@media only screen and (max-width: 480px) {
  /* line 38, ../sass/partials/_works.scss */
  .works .content-works {
    width: 48.3%;
    margin: 1% 0% 0% 1%;
  }
}
/* line 47, ../sass/partials/_works.scss */
.works .content-works .works-content {
  width: 70%;
  align-self: center;
  padding-top: 20%;
  padding-bottom: 20%;
}
/* line 52, ../sass/partials/_works.scss */
.works .content-works .works-content .works-icon {
  width: 40%;
  margin: auto;
}
/* line 56, ../sass/partials/_works.scss */
.works .content-works .works-content .works-title {
  text-align: center;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 3vw;
  font-weight: bold;
}
/* line 65, ../sass/partials/_works.scss */
.works .content-works:hover {
  cursor: pointer;
  background-color: #2e3131;
}
/* line 71, ../sass/partials/_works.scss */
.works .content-works-1 {
  background-color: #f22613;
}
/* line 74, ../sass/partials/_works.scss */
.works .content-works-2 {
  background-color: #19b5fe;
}
/* line 77, ../sass/partials/_works.scss */
.works .content-works-3 {
  background-color: #2ecc71;
}
/* line 80, ../sass/partials/_works.scss */
.works .content-works-4 {
  background-color: #4d13d1;
}
/* line 83, ../sass/partials/_works.scss */
.works .content-works-5 {
  background-color: #f9690e;
}
/* line 86, ../sass/partials/_works.scss */
.works .content-works-6 {
  background-color: #f7ca18;
}
/* line 90, ../sass/partials/_works.scss */
.works #worksModal {
  background-color: rgba(0, 0, 0, 0.8);
}
/* line 93, ../sass/partials/_works.scss */
.works #worksModal .modal-body ul {
  list-style-type: none;
  padding: 0px;
}
/* line 96, ../sass/partials/_works.scss */
.works #worksModal .modal-body ul li {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
}
/* line 97, ../sass/partials/_works.scss */
.works #worksModal .modal-body ul li .works-heading {
  font-weight: bold;
  padding: 10px 0px;
}
/* line 100, ../sass/partials/_works.scss */
.works #worksModal .modal-body ul li .works-heading .chevron {
  color: gray;
  float: right;
}
/* line 104, ../sass/partials/_works.scss */
.works #worksModal .modal-body ul li .works-heading:hover {
  cursor: pointer;
}
/* line 109, ../sass/partials/_works.scss */
.works #worksModal .modal-body ul li .works-description {
  display: none;
}

/* line 19, ../sass/main.scss */
.container-fluid {
  background-color: white;
}

/* line 24, ../sass/main.scss */
.center-screen-holder {
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 7;
  background-color: black;
}
/* line 34, ../sass/main.scss */
.center-screen-holder .center-screen {
  color: #FFD700;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 310px;
  height: 200px;
  border-radius: 3px;
  z-index: 8;
}
/* line 47, ../sass/main.scss */
.center-screen-holder .center-screen div p {
  text-align: center;
}

/* line 55, ../sass/main.scss */
.spacing {
  width: 100%;
  height: 55px;
}
@media only screen and (max-width: 1000px) {
  /* line 55, ../sass/main.scss */
  .spacing {
    height: 50px;
  }
}

/* line 64, ../sass/main.scss */
.modal {
  overflow-y: scroll;
}
